import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {getOauth2ClientNameByEmail, getOauth2RedirectUrl} from "../../utils/Common";
import Grid from "@mui/material/Grid";
import {LoginButton, Primary700Body2Link} from '../../themes/PortalStyling';
import ProfileBox from "../../components/ProfileBox";
import {useNavigate, useSearchParams} from "react-router-dom";
import axios from "axios";

const axiosInstance = axios.create();

export default function SsoEnforced() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const [email, setEmail] = React.useState(searchParams.get("email"));
    const [idp, setIdp] = React.useState("");

    useEffect(() => {
        getOauth2ClientNameByEmail(axiosInstance, email)
            .then((response) => setIdp(response.data.oauth2ClientName))
        if (email) {
            navigate("/login/sso-enforced", { replace: true });
        }
    }, [email, navigate]);

    function handleOauth2Signin() {
        window.location = getOauth2RedirectUrl(idp)
    }

    function returnToEmailEntry() {
        document.cookie = `ELEMICA_EMAIL=''; max-age=0; samesite=strict; secure`;
        navigate("/login")
    }

    return <>
        <Grid container justifyContent="center" direction="column" alignItems="center" style={{color: '#808080'}}>
            <Typography variant="h5">Single Sign On</Typography>
            <Typography>Your organization requires single sign-on for
                authentication.</Typography>
        </Grid>

        <ProfileBox email={email}/>

        <Grid item xs display="flex" justifyContent="center" sx={{mb: 3}}>
            <Primary700Body2Link onClick={returnToEmailEntry} sx={{textAlign: 'center'}}>
                Sign in with another email address
            </Primary700Body2Link>
        </Grid>

        <LoginButton
            type="button"
            fullWidth
            sx={{
                mt: 3,
                mb: 2
            }}
            variant="contained"
            onClick={handleOauth2Signin}
        >Continue
        </LoginButton>
    </>
}